@import '../styles/variables';

.header-appbar {
  background-color: $primary-color;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  height: 80px;
  width: auto;
  margin-right: 16px;
}

.spacer {
  flex-grow: 1; 
}

button {
  color: #FFFFFF;
  margin-left: 20px; 
  @media (max-width: 600px) {
    margin-left: 10px;
  }
}

.MuiDrawer-paper {
  width: 250px;
  background-color: $primary-color;
  transition: width 0.3s ease-in-out;
}

.MuiListItemText-root {
  color: #FFFFFF;
  padding: 10px 24px; 
  white-space: nowrap; 
}

.MuiMenu-paper {
  background-color: $primary-color;
  color: #FFFFFF;
}

.MuiMenuItem-root {
  color: #FFFFFF;
  &:hover {
    background-color: darken($primary-color, 10%);
  }
}
