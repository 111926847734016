@import '../styles/variables';

.services-section {
  padding: 4rem 2rem;
  background-color: #f9f9f9;
  text-align: center;

  .section-title {
    font-size: 2.5rem;
    font-weight: bold;
    color: $primary-color;
    margin-bottom: 2rem;
    animation: fadeIn 1s ease-in-out;
  }

  .intro-text {
    margin-bottom: 3rem;
    color: #555;
    font-size: 1.2rem;
    animation: fadeIn 1.5s ease-in-out;
  }

  .services-grid {
    margin-top: 2rem;
  }

  .service-box {
    background-color: white;
    border-radius: 8px;
    padding: 2rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    min-height: 400px; // Define a altura mínima
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &:hover {
      transform: translateY(-10px);
      box-shadow: 0 4px 16px rgba(0, 0, 0, 0.15);
    }

    .service-image {
      width: 100%;
      height: auto;
      border-radius: 8px;
      margin-bottom: 1.5rem;
      transition: transform 0.3s ease;

      &:hover {
        transform: scale(1.05);
      }
    }

    .service-title {
      color: $primary-color;
      font-size: 1.5rem;
      font-weight: 600;
      margin-bottom: 1rem;
    }

    .service-description {
      color: #666;
      font-size: 1rem;
      line-height: 1.5;
    }
  }

  @media (max-width: 768px) {
    .service-box {
      min-height: 350px; 
      margin-bottom: 2rem;
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
