@import '../styles/variables';

.home-section {
  padding: 4rem 2rem;
  background-color: #f9f9f9;

  .highlight-title {
    font-size: 2.5rem; 
    font-weight: bold;
    background: linear-gradient(90deg, $primary-color, $secondary-color);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; 
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2); 
    white-space: nowrap;
    display: inline-block;
    animation: fadeIn 2s ease-in-out; 
  }

  .subtitle {
    color: $primary-color;
    margin-bottom: 1rem;
    font-weight: 500;
  }

  .intro-text {
    margin-bottom: 2rem;
    font-size: 1.2rem;
    line-height: 1.6;
    color: #555;
  }

  .overview-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    &:hover {
      transform: scale(1.03);
      box-shadow: 0 4px 16px rgba(0, 0, 0, 0.15);
    }
  }

  .cta-button {
    margin-top: 2rem;
    padding: 10px 24px;
    font-size: 1rem;
    font-weight: bold;
    text-transform: uppercase;
  }

  @media (max-width: 768px) {
    padding: 2rem 1rem;

    .intro-text {
      font-size: 1.1rem;
    }

    .highlight-title {
      font-size: 2rem; 
    }

    .cta-button {
      width: 100%;
      text-align: center;
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
