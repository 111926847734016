@import '../styles/variables';

.contact-section {
  padding: 4rem 0;
  background-color: #f9f9f9;
  text-align: center;

  .section-title {
    font-size: 2.5rem;
    font-weight: bold;
    color: $primary-color;
    margin-bottom: 2rem;
    animation: fadeIn 1s ease-in-out;
  }

  .contact-info-grid {
    margin-bottom: 2rem;
  }

  .contact-info {
    a {
      color: $primary-color;
      text-decoration: none;
      font-weight: 500;

      &:hover {
        text-decoration: underline;
      }
    }
    display: flex;
    align-items: center;
    transition: transform 0.3s ease;

    &:hover {
      transform: translateY(-3px);
    }

    svg {
      transition: transform 0.3s ease;

      &:hover {
        transform: scale(1.2);
      }
    }
  }

  .form-title {
    margin-bottom: 1.5rem;
  }

  .contact-form-paper {
    padding: 2rem;
    background-color: white;
    border-radius: 8px;
    animation: fadeIn 1s ease-in-out;
  }

  .submit-button {
    margin-top: 1rem;
    width: 100%;
    padding: 12px;
    font-size: 1rem;
    font-weight: bold;
    text-transform: uppercase;
    transition: background-color 0.3s ease, transform 0.3s ease;

    &:hover {
      background-color: darken($primary-color, 10%);
      transform: translateY(-3px);
    }
  }

  .success-message {
    margin-top: 1rem;
    color: green;
    animation: fadeIn 1s ease-in-out;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
