@import './variables';
@import './mixins';

body {
  font-family: $font-family;
  margin: 0;
  padding: 0;
  background-color: #f4f4f4;
}

section {
  margin: 2rem 0;
}
